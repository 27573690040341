export const ThirdParty = {
  bind: {
    google: 'binding-google',
    facebook: 'binding-facebook',
    tiktok: 'binding-tiktok',
    instagram: 'binding-instagram',
  },
  auth: {
    google: 'google',
    facebook: 'facebook',
    tiktok: 'tiktok',
    instagram: 'instagram',
    dropbox: 'dropbox',
    oneDrive: 'oneDrive',
  },
} as const

export enum AuthDialogEnum {
  Login = 'login',
  SignUp = 'signUp',
  Default = 'default',
}

export const ThirdPartyAuthMode = {
  Login: 'login',
  SignUp: 'signUp',
} as const

export type ThirdPartyAuthModeType =
  (typeof ThirdPartyAuthMode)[keyof typeof ThirdPartyAuthMode]

// https://playsee.atlassian.net/wiki/spaces/AI/pages/495976458/AI+Gateway
export const NEED_LOG_OUT_ERROR_CODES = [
  751103002, 751103003, 751103004, 751103005, 751103006,
]
export const NEED_REFRESH_TOKEN_ERROR_CODES = [751103001, 751104001, 751104002]
