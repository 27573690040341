export interface IResp<T = object> {
  code: number
  message: string
  data: T
}

export const ApiCode = {
  success: 0,
}

/**
 * hermes api response
 */
export interface IRespV2<T = object> {
  code: number
  msg: string
  data: T
}

export type ISearchParams = Promise<{
  [key: string]: string | string[] | undefined
}>
export type IParams = Promise<{
  [key: string]: string
}>

export type IRequestProps = {
  params: IParams
  searchParams: ISearchParams
}
