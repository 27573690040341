export const generateParams = (params: {
  [key: string]: string | number | undefined
}) => {
  const newParams = new URLSearchParams()

  Object.entries(params).forEach(([key, value]) => {
    if (!value) return
    newParams.append(key, value.toString())
  })

  return newParams
}
