import { StripeAccountStatusEnum } from 'features/payment/constants'
import type {
  IInvoiceResponse,
  IPaymentMethods,
  IUserCheckoutPreferences,
  StripeAccountBalance,
} from 'features/payment/type'
import type { Fetch } from 'lib/fetch/type'
import { ApiCode, type IRespV2 } from 'type/common'

export const getUserInvoices = async (
  fetcher: Fetch,
  limit: string,
  cursor?: string,
): Promise<IInvoiceResponse | null> => {
  const params = new URLSearchParams()
  if (limit) params.append('limit', limit)
  if (cursor) params.append('cursor', cursor)
  try {
    const res: IRespV2<IInvoiceResponse> = await fetcher(
      fetcher.API.GW + `/v1/user/invoices?${params}`,
      {
        method: 'GET',
      },
    )
    if (res.code === ApiCode.success) {
      return res.data
    }
    return null
  } catch (error) {
    return null
  }
}

export const getUserPaymentMethods = async (
  fetcher: Fetch,
): Promise<IPaymentMethods | null> => {
  try {
    const res: IRespV2<IPaymentMethods> = await fetcher(
      fetcher.API.GW + `/v1/user/payment-methods`,
      {
        method: 'GET',
      },
    )
    if (res.code === ApiCode.success) {
      return res.data
    }
    return null
  } catch (error) {
    return null
  }
}

export const setUserDefaultPaymentMethod = async (
  fetcher: Fetch,
  payment_method_id: string,
): Promise<boolean> => {
  try {
    const res: IRespV2<{ message: string }> = await fetcher(
      fetcher.API.GW + `/v1/user/payment-methods/${payment_method_id}/default`,
      {
        method: 'POST',
      },
    )

    return res.code === ApiCode.success
  } catch (error) {
    return false
  }
}

export const deleteUserPaymentMethod = async (
  fetcher: Fetch,
  payment_method_id: string,
): Promise<boolean> => {
  try {
    const res: IRespV2<{ message: string }> = await fetcher(
      fetcher.API.GW + `/v1/user/payment-methods/${payment_method_id}`,
      { method: 'DELETE' },
    )

    return res.code === ApiCode.success
  } catch (error) {
    return false
  }
}

export const BindUserPaymentMethod = async (
  fetcher: Fetch,
  token: string,
): Promise<IInvoiceResponse | null> => {
  try {
    const res: IRespV2<IInvoiceResponse> = await fetcher(
      fetcher.API.GW + `/v1/user/payment-methods`,
      {
        method: 'POST',
        body: { token },
      },
    )
    if (res.code === ApiCode.success) {
      return res.data
    }
    return null
  } catch (error) {
    return null
  }
}

export const getUserCheckoutPreferences = async (
  fetcher: Fetch,
): Promise<IUserCheckoutPreferences | null> => {
  try {
    const res: IRespV2<IUserCheckoutPreferences> = await fetcher(
      fetcher.API.GW + `/v1/users/me/checkout-preferences`,
      {
        method: 'GET',
      },
    )
    if (res.code === ApiCode.success) {
      return res.data
    }
    return null
  } catch (error) {
    return null
  }
}

export const updateUserCheckoutPreferences = async (
  fetcher: Fetch,
  data: IUserCheckoutPreferences,
): Promise<IUserCheckoutPreferences | null> => {
  try {
    const res: IRespV2<IUserCheckoutPreferences> = await fetcher(
      fetcher.API.GW + `/v1/users/me/checkout-preferences`,
      {
        method: 'PATCH',
        body: data,
      },
    )
    if (res.code === ApiCode.success) {
      return res.data
    }
    return null
  } catch (error) {
    return null
  }
}

export const getUserStripeAccountStatus = async (
  fetcher: Fetch,
): Promise<keyof typeof StripeAccountStatusEnum> => {
  try {
    const res: IRespV2<{ status: keyof typeof StripeAccountStatusEnum }> =
      await fetcher(fetcher.API.GW + `/v1/users/me/stripe_account_status`, {
        method: 'GET',
      })
    if (res.code === ApiCode.success) {
      return res.data.status
    }
    return StripeAccountStatusEnum.inactive
  } catch (error) {
    return StripeAccountStatusEnum.inactive
  }
}

export const getUserStripeAccountLink = async (
  fetcher: Fetch,
  refresh_url: string,
  return_url: string,
): Promise<string | null> => {
  try {
    const params = new URLSearchParams({ refresh_url, return_url })
    const res: IRespV2<{ link: string }> = await fetcher(
      fetcher.API.GW + `/v1/users/me/stripe_account_link?${params}`,
      {
        method: 'GET',
      },
    )
    if (res.code === ApiCode.success) {
      return res.data.link
    }
    return null
  } catch (error) {
    return null
  }
}

export const getUserStripeAccountBalance = async (
  fetcher: Fetch,
): Promise<StripeAccountBalance | null> => {
  try {
    const res: IRespV2<StripeAccountBalance> = await fetcher(
      fetcher.API.GW + `/v1/users/me/stripe_account_balance`,
      {
        method: 'GET',
      },
    )
    if (res.code === ApiCode.success) {
      return res.data
    }
    return null
  } catch (error) {
    return null
  }
}
