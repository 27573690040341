import type { GeoPoint } from '@paladise/tracker/types'
import type {
  IUser,
  IUserAuth,
  Profile,
  TermsData,
  UserInfo,
} from 'features/account/types'
import type { IBusinessInfo } from 'features/avatarProfile/type'
import type { Fetch } from 'lib/fetch/type'
import { ApiCode, type IResp, type IRespV2 } from 'type/common'
import { generateParams } from 'utils/generate-params'

export const getUserByUserId = async (
  fetcher: Fetch,
  id: string,
  language?: string,
): Promise<IResp<IUser>> => {
  const queryParams = generateParams({
    language: language || '',
  })
  const res = await fetcher(fetcher.API.GW + `/v1/users/${id}?${queryParams}`)
  return res
}

export const getUser = async (fetcher: Fetch): Promise<UserInfo | null> => {
  try {
    const res: IRespV2<UserInfo> = await fetcher(
      fetcher.API.GW + `/v1/users/me/profile`,
    )
    return res.data
  } catch (error) {
    return null
  }
}

export const getUserLocation = async (fetcher: Fetch): Promise<GeoPoint> => {
  try {
    const res: IResp<GeoPoint> = await fetcher(
      fetcher.API.GW + `/v1/users/me/location`,
    )

    return res.data
  } catch (error) {
    return {
      geo_point: { lat: '0', lng: '0' },
    }
  }
}

export const checkUserEmail = async (
  fetcher: Fetch,
  email: string,
): Promise<IResp<{ available: boolean }>> => {
  const res = await fetcher(
    fetcher.API.GW + `/v1/users/email-availability?email=${email}`,
  )

  return res
}

export const checkUsername = async (
  fetcher: Fetch,
  username: string,
): Promise<IResp<{ available: boolean }>> => {
  const res = await fetcher(
    fetcher.API.GW + `/v1/users/name-availability?name=${username}`,
  )

  return res
}

export const deleteUser = async (
  fetcher: Fetch,
  body: { message: string },
): Promise<IRespV2<string>> => {
  const res = await fetcher(fetcher.API.GW + `/v1/user`, {
    method: 'DELETE',
    body,
  })

  return res
}

export const updateUserProfile = async (
  fetcher: Fetch,
  profile: Profile,
): Promise<IResp<string>> => {
  const res = await fetcher(fetcher.API.GW + '/v1/users/me/profile', {
    method: 'PATCH',
    body: JSON.stringify(profile),
  })

  return res
}

export const changeUserPassword = async (
  fetcher: Fetch,
  data: {
    current_password?: string
    new_password: string
  },
): Promise<IRespV2<string>> => {
  const res = await fetcher(fetcher.API.GW + '/v1/users/me/password', {
    method: 'PATCH',
    body: JSON.stringify(data),
  })

  return res
}

export const resetUserPassword = async (
  fetcher: Fetch,
  data: {
    email: string
  },
): Promise<IResp<string>> => {
  const res = await fetcher(fetcher.API.GW + '/v1/users/password/reset', {
    method: 'POST',
    body: JSON.stringify({ ...data, is_biz: false }),
  })

  return res
}

export const resetUserPasswordCallback = async (
  fetcher: Fetch,
  data: {
    token: string
    password: string
  },
): Promise<IResp<string>> => {
  const res = await fetcher(
    fetcher.API.GW + '/v1/users/password/reset-validation',
    {
      method: 'POST',
      body: JSON.stringify({ ...data, is_biz: false }),
    },
  )

  return res
}

export const sendUserVerificationEmail = async (
  fetcher: Fetch,
): Promise<IRespV2<string>> => {
  const res = await fetcher(fetcher.API.GW + '/v1/users/email/verification', {
    method: 'POST',
  })

  return res
}

export const getTermsData = async (fetcher: Fetch, type: string) => {
  let isError: boolean = false
  try {
    const query = new URLSearchParams()
    query.append('subject', type)
    const { data }: IResp<TermsData> = await fetcher(
      fetcher.API.GW + `/v1/terms?${query}`,
    )

    if ('error' in data || !data.created_at) isError = true
    return { data, isError }
  } catch (error) {
    console.log('🚀 ~ getTermsData ~ error:', error)
    isError = true
    return { isError }
  }
}

export const getBusinessInfo = async ({
  fetcher,
  user_id = '',
  company_id = '',
}: {
  fetcher: Fetch
  user_id?: string
  company_id?: string
}): Promise<IRespV2<IBusinessInfo>> => {
  const queryParams = generateParams({
    user_id,
    company_id,
  })

  const res = await fetcher(
    fetcher.API.GW + `/v1/companies/search?${queryParams}`,
    {
      method: 'GET',
    },
  )
  return res
}

export const updateUserTimeZone = async (fetcher: Fetch, time_zone: string) => {
  try {
    const res = await fetcher(fetcher.API.GW + '/v1/users/me/timezone', {
      method: 'PATCH',
      body: JSON.stringify({ time_zone }),
    })
    return res
  } catch (error) {
    return { error }
  }
}

export const bindUserTikTok = async ({
  fetcher,
  body,
}: {
  fetcher: Fetch
  body: {
    open_id: string
    access_token: string
    expires_in: number
    refresh_expires_in: number
    refresh_token: string
  }
}): Promise<IRespV2<string>> => {
  const result = await fetcher(
    fetcher.API.USER + `/web/v1/user/tiktok/authorization`,
    {
      method: 'POST',
      body,
    },
  )

  return result
}

export const unbindUserTikTok = async ({
  fetcher,
}: {
  fetcher: Fetch
}): Promise<boolean> => {
  try {
    const res: IRespV2<string> = await fetcher(
      fetcher.API.USER + `/web/v1/user/tiktok/authorization`,
      {
        method: 'DELETE',
      },
    )

    if (res.code === ApiCode.success) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export const bindUserInstagram = async ({
  fetcher,
  body,
}: {
  fetcher: Fetch
  body: {
    access_token: string
    expires_in: number
  }
}): Promise<IRespV2<string>> => {
  const result = await fetcher(
    fetcher.API.USER + `/web/v1/user/ig/authorization`,
    {
      method: 'POST',
      body,
    },
  )

  return result
}

export const unbindUserInstagram = async ({
  fetcher,
}: {
  fetcher: Fetch
}): Promise<boolean> => {
  try {
    const res: IRespV2<string> = await fetcher(
      fetcher.API.USER + `/web/v1/user/ig/authorization`,
      {
        method: 'DELETE',
      },
    )

    if (res.code === ApiCode.success) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export const checkUserAuth = async ({
  fetcher,
}: {
  fetcher: Fetch
}): Promise<IUserAuth> => {
  try {
    const res: IRespV2<IUserAuth> = await fetcher(
      fetcher.API.USER + `/web/v1/user/authorization`,
      {
        method: 'GET',
      },
    )

    return res.data
  } catch (error) {
    console.log('🚀 ~ checkUserAuth ~ error:', error)
    return {} as IUserAuth
  }
}
