import type { ChatHistory, ChatListItem, Message } from 'features/chat/type'
import { type Fetch } from 'lib/fetch/type'
import type { IResp, IRespV2 } from 'type/common'

export const getChatHistory = async (
  fetcher: Fetch,
  user_id: string,
): Promise<IResp<ChatHistory[]>> => {
  const res = await fetcher(
    fetcher.API.CHAT + `/web/v1/chat/chat_history_list/${user_id}`,
  )

  return res
}

export const deleteChatHistory = async (
  fetcher: Fetch,
  data: {
    avatar_ids: string[]
    user_id: string
  },
): Promise<IRespV2> => {
  const res = await fetcher(fetcher.API.CHAT + `/web/v1/chat/delete_history`, {
    method: 'POST',
    body: data,
  })

  return res
}

export const checkCredit = async (
  fetcher: Fetch,
  userId: string,
): Promise<IRespV2> => {
  const res = await fetcher(
    fetcher.API.CHAT + `/web/v1/payment/credit_check/${userId}`,
    {
      method: 'POST',
    },
  )

  return res
}

export const getLastChatList = async (
  fetcher: Fetch,
  query: {
    page: number
    page_size: number
  },
): Promise<IRespV2<ChatListItem[]>> => {
  const res = await fetcher(fetcher.API.GW + `/v1/users/me/chats/history`, {
    method: 'GET',
    query,
  })

  return res
}

export const getChatroomHistory = async (
  fetcher: Fetch,
  body: {
    user_id: string
    avatar_id: string
    session_key: string
    prev_limit: number
    next_limit: number
  },
): Promise<IRespV2<Message[]>> => {
  const res = await fetcher(
    fetcher.API.CHAT + `/web/v1/chat/get_chat_room_history`,
    {
      method: 'POST',
      body,
    },
  )

  return res
}

export const searchChatHistory = async (
  fetcher: Fetch,
  query: {
    keyword: string
    page: number
    page_size: number
  },
): Promise<IRespV2<ChatListItem[]>> => {
  const res = await fetcher(
    fetcher.API.GW + `/v1/users/me/chats/history/messages/search`,
    {
      query,
    },
  )

  return res
}

export const feedbackChat = async (
  fetcher: Fetch,
  userId: string,
  body: {
    avatar_id: string
    status: string
    session_id: string
  },
): Promise<void> => {
  await fetcher(fetcher.API.CHAT + `/web/v1/chat/like_status/` + userId, {
    method: 'POST',
    body,
  }).catch(e => {
    console.log(e)
  })
}

export const getUnreadStatus = async (
  fetcher: Fetch,
): Promise<
  IRespV2<{
    has_unread: boolean
    last_chat_unread_count: number
    chat_history_unread_count: number
  }>
> => {
  const res = await fetcher(
    fetcher.API.GW + `/v1/users/me/chats/history/messages/get_read_status`,
  )

  return res
}

export const setReadStatus = async (
  fetcher: Fetch,
  avatarId: string,
): Promise<IRespV2> => {
  try {
    const res = await fetcher(
      fetcher.API.GW +
        `/v1/users/me/chats/history/messages/mark_read/${avatarId}`,
      {
        method: 'POST',
      },
    )

    return res
  } catch (error) {
    return {
      code: 0,
      data: {},
      msg: '',
    }
  }
}

export const facialDetect = async ({
  fetcher,
  userId,
  file,
  avatarId,
}: {
  fetcher: Fetch
  userId: string
  file: File
  avatarId: string
}): Promise<{ result: boolean; info: string | null; image_url: string }> => {
  const formData = new FormData()
  formData.append('source_image', file)
  formData.append('avatar_id', avatarId)

  try {
    const res: IRespV2<{
      result: boolean
      info: string | null
      image_url: string
    }> = await fetcher(
      fetcher.API.CHAT + `/web/v1/facial/detect_and_crop/${userId}`,
      {
        method: 'POST',
        body: formData,
      },
    )

    return res.data
  } catch (error) {
    return {
      result: false,
      info: null,
      image_url: '',
    }
  }
}
