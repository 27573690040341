import {
  AUTH_COOKIE_NAME,
  REFRESH_COOKIE_NAME,
} from '@paladise/config/constants'
import { type CookieSetter } from '../types'
import { handleRefreshToken } from '../actions/refresh-token'

export const refreshTokenAndSetCookie = async (
  cookieStore: { set: CookieSetter },
  refreshToken: string,
): Promise<string | null> => {
  const res = await handleRefreshToken(refreshToken)

  if (!res) {
    return null
  }

  cookieStore.set(AUTH_COOKIE_NAME, res.access_token, {
    httpOnly: false,
    expires: new Date(res.access_token_expire_at),
  })
  cookieStore.set(REFRESH_COOKIE_NAME, res.refresh_token, {
    httpOnly: false,
    expires: new Date(res.refresh_token_expire_at),
  })

  return res.access_token
}
