'use client'

import type { UserInfo } from 'features/auth/types'

interface IServerContext {
  session: UserInfo
  v2Token: string
  isEmbed: boolean
  location: {
    lat: string
    lng: string
  }
  env: {
    CONSUMER_BASE_URL: string
    ENTERPRISE_BASE_URL: string
    ENV: string
    COMMIT_HASH: string
    STRIPE_PUBLIC_KEY: string
    TIKTOK_CLIENT_KEY: string
    TIKTOK_CLIENT_SECRET: string
    FACEBOOK_CLIENT_ID: string
    FACEBOOK_CLIENT_SECRET: string
    INSTAGRAM_CLIENT_ID: string
    INSTAGRAM_CLIENT_SECRET: string
    DROPBOX_CLIENT_ID: string
    DROPBOX_CLIENT_SECRET: string
    GOOGLE_API_KEY: string
    ONE_DRIVE_CLIENT_ID: string
    ONE_DRIVE_CLIENT_SECRET: string

    // API
    API_ASGARD: string
    API_USER: string
    API_MEDIA: string
    API_SESSION: string
    API_HERMES: string
    API_CHAT: string
    API_REPORT: string
    API_GW: string
    // HOST
    HOST_MQTT: string
    HOST_NOTIFY: string
    HOST_TRACKER: string
  }
}

export let session: IServerContext['session'] = undefined!
export let v2Token: IServerContext['v2Token'] = ''
export let isEmbed: IServerContext['isEmbed'] = false
export let location: IServerContext['location'] = { lat: '0', lng: '0' }

export let env: IServerContext['env'] = {
  CONSUMER_BASE_URL: '',
  ENTERPRISE_BASE_URL: '',
  ENV: '',
  COMMIT_HASH: '',
  STRIPE_PUBLIC_KEY: '',
  TIKTOK_CLIENT_KEY: '',
  TIKTOK_CLIENT_SECRET: '',
  FACEBOOK_CLIENT_ID: '',
  FACEBOOK_CLIENT_SECRET: '',
  INSTAGRAM_CLIENT_ID: '',
  INSTAGRAM_CLIENT_SECRET: '',
  DROPBOX_CLIENT_ID: '',
  DROPBOX_CLIENT_SECRET: '',
  GOOGLE_API_KEY: '',
  ONE_DRIVE_CLIENT_ID: '',
  ONE_DRIVE_CLIENT_SECRET: '',
  // API
  API_ASGARD: '',
  API_USER: '',
  API_MEDIA: '',
  API_SESSION: '',
  API_HERMES: '',
  API_CHAT: '',
  API_REPORT: '',
  API_GW: '',

  // HOST
  HOST_MQTT: '',
  HOST_NOTIFY: '',
  HOST_TRACKER: '',
}

export const StaticServerContext = (props: IServerContext) => {
  session = props.session
  v2Token = props.v2Token
  isEmbed = props.isEmbed
  location = props.location
  env = props.env

  return null
}
